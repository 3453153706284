import http from '@/app/services/httpServices';
import URL_UTILITY from '@/app/utility/url.utility';

const getProvisonalHeaderData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/provisional-header',
    payload
  );
};
const getProvisonalDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl +
      '/provisonHdrByid' +
      '?prov_hdr_id=' +
      payload.prov_hdr_id
  );
};
const getCurrencyDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl +
      '/exchange-rate-currency' +
      '?legal_entity_id=' +
      payload.legal_entity_id
  );
};
const getExpenseMonth = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/provison-expense-month-period',
    payload
  );
};
const getPoNumberDetails = (context, payload) => {
  return http.postApi(
    // URL_UTILITY.getProvisionsUrl + '/provison-po-by-vendor-id',
    URL_UTILITY.getProvisionsUrl + '/getByVendorID',
    payload
  );
};
const getPoLineNumberDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/provision-po-line-details',
    payload
  );
};
const addEditProvisionDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/provision-add-provision-data',
    payload
  );
};
// const getProvisonDetailsById = (context, payload) => {
//   return http.getApi(
//     URL_UTILITY.getProvisionsUrl +
//       '/getByProvison-Hdrid?prov_hdr_id=' +
//       payload.prov_hdr_id
//   );
// };
const getAccCodeDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/provision-invoices-po-line-details',
    payload
  );
};
const getStateCodeDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/get-state-code',
    payload
  );
};
const getCostCenterDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/get-costcenter',
    payload
  );
};
const getAccNoDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/get-account-no',
    payload
  );
};
const getSubAccNoDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/get-sub-account-no',
    payload
  );
};
const getLobCompanyDepartmentDetails = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/get-by-segments',
    payload
  );
};
const getProvisionNoDetails = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/get-provision-account',
    payload
  );
};
const uploadProvisionData = (context, payload) => {
  return http.postApi(URL_UTILITY.getProvisionsUrl+'/provision-excel-upload/'+ payload.excel_import_id+'/'+'excel-upload');
};
const getProvisonHdrById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl +
      '/provisonHdrDataByid?prov_hdr_id=' +
      payload.prov_hdr_id, payload.requestPayload
  );
};
const getProvisonDtlById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl +
      '/provisonDtlDataByid?prov_hdr_id=' +
      payload.prov_hdr_id, payload.requestPayload
  );
};
const validateUploadProvision = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/verifyDetails',
    payload
  );
};
const getApprovedBatch = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/excel_post',
    payload
  );
};
const getReProvisionData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/re-provision-dashboard',
    payload
  );
};
const getVendorData = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/get-oracle-vendors',
    payload
  );
};
const getProvisionAccountMappingData = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getProvisionsUrl + '/get-provision-account-mapping',
    payload
  );
};
const provisionSubmitRequest = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getProvisionsUrl + '/provision_submit_request',
    payload
  );
};
export default {
  getProvisonalHeaderData,
  getProvisonalDetails,
  getCurrencyDetails,
  getExpenseMonth,
  getPoNumberDetails,
  getPoLineNumberDetails,
  addEditProvisionDetails,
  // getProvisonDetailsById,
  getAccCodeDetails,
  uploadProvisionData,
  getProvisonHdrById,
  getProvisonDtlById,
  validateUploadProvision,
  getApprovedBatch,
  getReProvisionData,
  getStateCodeDetails,
  getCostCenterDetails,
  getAccNoDetails,
  getSubAccNoDetails,
  getProvisionNoDetails,
  getLobCompanyDepartmentDetails,
  getVendorData,
  getProvisionAccountMappingData,
  provisionSubmitRequest
};