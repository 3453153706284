const appStrings = {
  autoFailedMsg: 'Something went wrong please try again.',
  countrySuccessMsg: 'Country updated successfully',
  countryAddedMsg: 'Country added successfully',
  stateSuccessMsg: 'State updated successfully',
  stateAddedMsg: 'State added successfully',
  citySuccessMsg: 'City updated successfully',
  cityAddedMsg: 'City added successfully',
  pincodeSuccessMsg: 'City updated successfully',
  pincodeAddedMsg: 'City added successfully',
  addressUpdatedSuccessMsg: 'Address updated successfully',
  addressAddedSuccessMsg: 'Address added successfully',
  currencySuccessMsg: 'Currency updated successfully',
  currencyAddedMsg: 'Currency added successfully',
  siteTypeVset: 'NORMAL, LEASE',
  defaultApprovalStatus: 'DRAFT',

  VSETTYPEFORVSETNAME: {
    CAT: 'CAT',
    SUB_CAT: 'SUB_CAT',
    SUB_SUB_CAT: 'SUB_SUB_CAT',
    SOURCE: 'SOURCE',
    PRIORITY: 'PRIORITY',
    STATUS: 'STATUS',
    ENTITY_TYPE: 'ENTITY_TYPE'
  },

  FMSLOV: {
    FMSPROJECT: 'FMSPROJECT',
    FMSSECTOR: 'FMSSECTOR',
    FMSPOCKET: 'FMSPOCKET',
    FMSTOWER: 'FMSTOWER',
    FMSFLOOR: 'FMSFLOOR',
    FMSUNIT: 'FMSUNIT',
    FMSSUBUNIT: 'FMSSUBUNIT'
  },
  VALUESETTYPE: {
    FMS_SEARCH_BY: 'FMS_SEARCH_BY',
    PAYMENT_MODE: 'PAYMENT_MODE',
    BANK_NAME_VSET: 'BANK_NAME_VSET',
    INSTRUMENT_STATUS: 'INSTRUMENT_STATUS',
    PARTY_TYPE: 'PARTY_TYPE',
    PARTY_CAT: 'PARTY_CAT',
    BANK_LIST: 'BANK_LIST',
    BANK_ACC_TYPE: 'BANK_ACC_TYPE',
    CONTACT_PURPOSE_VSET: 'CONTACT_PURPOSE_VSET',
    POSITION: 'POSITION',
    WF_GROUP_TYPE: 'WF_GROUP_TYPE',
    NFA_TYPE: 'NFA_TYPE',
    NFA_CATEGORY: 'NFA_CATEGORY',
    NFA_SUB_CATEGORY: 'NFA_SUB_CATEGORY',
    NFA_LOCATION: 'NFA_LOCATION',
    LEGAL_ENTITY_LIST: 'LEGAL_ENTITY_LIST',
    OU_LIST: 'OU_LIST',
    PERIOD_CLOSED_LEVEL: 'PERIOD_CLOSED_LEVEL',
    FMS_PROJECT: 'FMS_PROJECT',
    LEASE_RECOVERY: 'LEASE_RECOVERY',
    LOCATION: 'LOCATION',
    COMM_TEMPLATE: 'COMM_TEMPLATE',
    UOM: 'UOM',
    UOM_CLASS: 'UOM_CLASS',
    LEASE_PROJECT_LIST: 'LEASE_PROJECT_LIST',
    LEASE_TOWER_LIST: 'LEASE_TOWER_LIST',
    LEASE_FLOOR_LIST: 'LEASE_FLOOR_LIST',
    LEASE_UNIT_LIST: 'LEASE_UNIT_LIST',
    RECOVERY_BASIS: 'RECOVERY_BASIS',
    RECOVERY_MODE_VSET: 'RECOVERY_MODE_VSET',
    FMS_CAT: 'FMS_CAT',
    FMS_SUB_CAT: 'FMS_SUB_CAT',
    FMS_SUB_SUB_CAT: 'FMS_SUB_SUB_CAT',
    FMS_QUERY_SOURCE: 'FMS_QUERY_SOURCE',
    FMS_PRIORITY: 'FMS_PRIORITY',
    FMS_STATUS: 'FMS_STATUS',
    FMS_ENTITY_TYPE: 'FMS_ENTITY_TYPE',
    NFA_FMS_PROJECT: 'NFA_FMS_PROJECT',
    NFA_FMS_SECTOR: 'NFA_FMS_SECTOR',
    NFA_FMS_POCKET: 'NFA_FMS_POCKET',
    NFA_FMS_TOWER: 'NFA_FMS_TOWER',
    NFA_FMS_FLOOR: 'NFA_FMS_FLOOR',
    NFA_FMS_UNIT: 'NFA_FMS_UNIT',
    NFA_FMS_SUB_UNIT: 'NFA_FMS_SUB_UNIT',
    TAX_BASIS: 'TAX_BASIS',
    INV_BATCH_NAME: 'INV_BATCH_NAME',
    SCHEDULE_LINE_TYPE_VSET: 'SCHEDULE_LINE_TYPE_VSET',
    PAY_GROUP_TYPE: 'PAY_GROUP_TYPE',
    DESIGNATION: 'DESIGNATION',
    GL_PERIOD: 'GL_PERIOD',
    GST_FIRST_PARTY_GSTN: 'GST_FIRST_PARTY_GSTN',
    BILLING_CYCLE: 'BILLING_CYCLE',
    INVENTORY_ORG_LIST: 'INVENTORY ORG LIST',
    SELECT_CRM_PROJECT: 'SELECT_CRM_PROJECT',
    ERP_SITE_NAME: 'ERP_SITE_NAME',
    ERP_INVENTORY_NAME: 'ERP_INVENTORY_NAME',
    ERP_LOCATION_NAME: 'ERP_LOCATION_NAME',
    FMS_COMP_TABLE: 'FMS_COMP_TABLE',
    PRJ_POCKET: 'PRJ_POCKET',
    PRJ_PROJECT: 'PRJ_PROJECT',
    BILLING_PLAN: 'BILLING PLAN',
    SELECT_CRM_TOWER: 'SELECT CRM TOWER',
    SELECT_CRM_FLOOR: 'SELECT CRM FLOOR',
    SELECT_CRM_UNIT: 'SELECT CRM UNIT',
    CRM_SECTOR: 'CRM_SECTOR',
    CRM_POCKET: 'CRM POCKET',
    PRJ_SECTOR: 'PRJ_SECTOR',
    TAX_CATEGORY_NAME_VSET: 'TAX_CATEGORY_NAME_VSET',
    INV_MASTER_ORG: 'INV_MASTER_ORG',
    TABLE_COLUMNS: 'TABLE_COLUMNS',
    WF_CRITERIA_SPEC: 'WF_CRITERIA_SPEC',
    UNIT_SUB_CAT: 'UNIT_SUB_CAT',
    WF_CRITERIA_SUB_CAT: 'WF_CRITERIA_SUB_CAT',
    APPROVAL_STATUS: 'APPROVAL_STATUS',
    SCHEDULE_STATUS_VSET: 'SCHEDULE_STATUS_VSET',
    LEASE_SCHEDULE_TYPE: 'LEASE SCHEDULE TYPE',
    CC_CAT: 'CC_CAT',
    CC_SUB_CAT: 'CC_SUB_CAT',
    CC_SUB_SUB_CAT: 'CC_SUB_SUB_CAT',
    CC_QUERY_SOURCE: 'CC_QUERY_SOURCE',
    CC_STATUS: 'CC_STATUS',
    CC_PRIORITY: 'CC_PRIORITY',
    CC_ENTITY_TYPE: 'CC_ENTITY_TYPE',
    CUSTOMER_NAME: 'CUSTOMER_NAME', // to get all customer
    TRX_VALIDATION: 'TRX_VALIDATION',
    RECEIPT_TYPE: 'RECEIPT_TYPE',
    VATIKA_GL_Accounts: 'VATIKA_GL_Accounts',
    SOURCE: 'SOURCE',
    MASTER_LEASE_RECEIVING_BANK_VSET: 'MASTER_LEASE_RECEIVING_BANK_VSET',
    ASSET_TYPE: 'ASSET_TYPE',
    ASSET_STATUS: 'ASSET_STATUS',
    ASSET_OWNED_TYPE: 'ASSET_OWNED_TYPE',
    ASSET_BOOK_TYPE: 'ASSET_BOOK_TYPE',
    ASSET_CATEGORY: 'ASSET_CATEGORY',
    ASSET_LOCATION: 'ASSET_LOCATION',
    ASSET_TRANSFER_BATCH_STATUS: 'ASSET_TRANSFER_BATCH_STATUS',
    ASSET_PV_STATUS: 'ASSET_PV_STATUS',
    Select_Employee: 'Select Employee',
    TAX_GROUP_NAME_VSET: 'TAX_GROUP_NAME_VSET',
    BATCH_PAYMENT_STATUS: 'BATCH_PAYMENT_STATUS',
    PP_APPROVAL_STATUS: 'PP_APPROVAL_STATUS',
    GSTN_STATUS: 'GSTN_STATUS',
    LEASE_PARTY_TYPE: 'LEASE_PARTY_TYPE',
    MUTATION_TYPE: 'MUTATION_TYPE',
    FMS_TOWER: 'FMS_TOWER',
    FMS_FLOOR: 'FMS_FLOOR',
    FMS_UNIT: 'FMS_UNIT',
    H2H_BANK_FILE: 'H2H_BANK_FILE',
    FMS_PARTY_TYPE: 'FMS_PARTY_TYPE',
    MUTATION_PARTY_TYPE: 'MUTATION_PARTY_TYPE',
    DUNNING_RULE_MASTER_LIST: 'DUNNING_RULE_MASTER_LIST',
    DUNNING_LETTER_MASTER_LIST: 'DUNNING_LETTER_MASTER_LIST',
    TDS_TAX_CATEGORY_ACTUAL: 'TDS_TAX_CATEGORY_ACTUAL',
    TDS_SECTION_ACTUAL: 'TDS_SECTION_ACTUAL',
    COST_CENTER_LIST: 'COST_CENTER_LIST',
    WAREHOUSE_LIST: 'WAREHOUSE_LIST',
    DUNNING_SETUP_TYPE: 'DUNNING_SETUP_TYPE',
    DUNNING_SETUP_SUB_TYPE: 'DUNNING_SETUP_SUB_TYPE',
    DUNNING_LETTER_FORMAT: 'DUNNING_LETTER_FORMAT',
    RGP_FORM_STATUS: 'RGP_FORM_STATUS',
    RECEIPT_REVERSAL_REASON: 'RECEIPT_REVERSAL_REASON',
    TRANSACTION_TYPE_CODE: 'TRANSACTION_TYPE_CODE',
    ASSET_BOOK_CODE: 'ASSET_BOOK_CODE',
    TO_FA_LOCATION: 'TO_FA_LOCATION',
    POST_TO_ORACLE_STATUS: 'POST_TO_ORACLE_STATUS',
    DUNNING_LETTER_RULE_FORMAT: 'DUNNING_LETTER_RULE_FORMAT',
    AR_TRX_TYPE: 'AR_TRX_TYPE',
    TASK_VSET: 'TASK_VSET',
    TASK_STATUS_VSET: 'TASK_STATUS_VSET',
    CRITICALITY_VSET: 'CRITICALITY_VSET',
    TRX_TYPE: 'TRX_TYPE',
    ASSIGNEE_TYPE: 'ASSIGNEE_TYPE',
    DEPARTMENT: 'DEPARTMENT',
    DUNNING_RULE_LIST: 'DUNNING_RULE_LIST',
    DUNNING_RULE_DAYS_BASIS: 'DUNNING_RULE_DAYS_BASIS',
    TDS_RATE_ACTUAL: 'TDS_RATE_ACTUAL',
    EXCEL_STATUS: 'EXCEL_STATUS',
    CC_EMPLOYEE: 'CC_EMPLOYEE',
    CC_GROUP: 'CC_GROUP',
    CC_DEPARTMENT: 'CC_DEPARTMENT',
    SOURCING_APP_FREIGHT: 'SOURCING_APP_FREIGHT',
    CC_TAG_TYPE: 'CC_TAG_TYPE',
    EMAIL_TAGGED_STATUS: 'EMAIL_TAGGED_STATUS',
    DUNNING_CUSTOMER_LETTER_CRITERIA: 'DUNNING_CUSTOMER_LETTER_CRITERIA',
    DUNNING_CRITERIA: 'DUNNING_CRITERIA',
    DUNNING_EMAIL_STATUS: 'DUNNING_EMAIL_STATUS',
    HOLD_REASON: 'HOLD_REASON',
    RELEASE_REASON: 'RELEASE_REASON',
    STATUS_VSET: 'STATUS_VSET',
    RGP_INV_ITEMS: 'RGP_INV_ITEMS',
    TERM_CON_VSET: 'TERM_CON_VSET',
    TERM_CON_TYPE_VSET: 'TERM_CON_TYPE_VSET',
    ACTIVITY_TYPE_VSET: 'ACTIVITY_TYPE_VSET',
    AR_ACTIVITY_MASTER_VSET: 'AR_ACTIVITY_MASTER_VSET',
    PAYMENT_TYPE_VSET: 'PAYMENT_TYPE_VSET',
    GST_RECONCILATION_STATUS: 'GST_RECONCILATION_STATUS',
    ESCALATION_TYPE_VSET: 'ESCALATION_TYPE_VSET',
    AR_TRX_TYPE_APPLY_UNAPPLY_VSET: 'AR_TRX_TYPE_APPLY_UNAPPLY_VSET',
    TENANT_LEASE_ESC_NUM: 'TENANT_LEASE_ESC_NUM',
    MANUAL_PAYMENT_EXTERNAL_BANK_AC: 'MANUAL_PAYMENT_EXTERNAL_BANK_AC',
    BRANCHES_BY_PARTY: 'BRANCHES_BY_PARTY',
    ACCOUNTS_BY_BRANCH: 'ACCOUNTS_BY_BRANCH',
    INVOICE_FLAG_VSET: 'INVOICE_FLAG_VSET',
    GL_ACTIVE_VSET: 'GL_ACTIVE_VSET',
    VATIKA_GL_Company: 'VATIKA_GL_Company',
    VATIKA_GL_Line_of_Business: 'VATIKA_GL_Line of Business',
    PAYMENT_SERIES_AP: 'PAYMENT_SERIES_AP',
    CRM_MASTER_PROJECT: 'CRM_MASTER_PROJECT',
    FLOOR_TYPE_VSET: 'FLOOR_TYPE_VSET',
    PRJ_MASTER_PROJECT: 'PRJ_MASTER_PROJECT',
    PRJ_TYPE: 'PRJ_TYPE',
    COF_STATUS: 'COF_STATUS',
    FMS_PAYMENT_TERM: 'FMS_PAYMENT_TERM',
    COMPONENT_GRP: 'COMPONENT_GRP',
    FMS_COMPONENT: 'FMS_COMPONENT',
    Currency: 'Currency',
    MODULE_LIST: 'MODULE_LIST',
    FMS_CUSTOMER_BY_SUBUNIT: 'FMS_CUSTOMER_BY_SUBUNIT',
    FMS_CUSTOMER_BY_PROJ: 'FMS_CUSTOMER_BY_PROJ',
    LEASE_CUSTOMER_BY_UNIT: 'LEASE_CUSTOMER_BY_UNIT',
    LEASE_CUSTOMER_BY_PRJ: 'LEASE_CUSTOMER_BY_PRJ',
    CURRENCY_LIST: 'CURRENCY_LIST',
    GST_RECO_TYPE: 'GST_RECO_TYPE',
    UNIT_CAT: 'UNIT_CAT',
    AP_PAYMENT_STATUS: 'AP_PAYMENT_STATUS',
    SOURCE_LIST: 'SOURCE_LIST',
    COF_CATEGORY: 'COF_CATEGORY',
    FMS_COMP_GROUP_TABLE: 'FMS_COMP_GROUP_TABLE',
    FMS_TAX_CAT: 'FMS_TAX_CAT',
    LEASE_TAX_CAT: 'LEASE_TAX_CAT',
    TAX_TYPE: 'TAX_TYPE',
    REGIME_ACCOUNT_TYPE: 'REGIME_ACCOUNT_TYPE',
    TAX_TYPE_NAME_VSET: 'TAX_TYPE_NAME_VSET',
    ASSET_RECO_STATUS: 'ASSET_RECO_STATUS',
    ASSET_PRINT_STATUS: 'ASSET_PRINT_STATUS',
    ASSET_TAGGED_STATUS: 'ASSET_TAGGED_STATUS',
    GST_PERIODICAL_TYPE: 'GST_PERIODICAL_TYPE',
    FINANCIAL_YEAR_LIST: 'FINANCIAL_YEAR_LIST',
    COF_CAT_BY_LE: 'COF_CAT_BY_LE',
    ITC_TYPE_2A_2B: 'ITC_TYPE_2A_2B',
    GST_OPERATING_UNIT: 'GST_OPERATING_UNIT',
    GST_INVOICE_LOOKUP_TYPE: 'GST_INVOICE_LOOKUP_TYPE',
    LEASE_PARTY_MLA: 'LEASE_PARTY_MLA',
    ASSET_SPOTON_LOC_VSET: 'ASSET_SPOTON_LOC_VSET',
    REV_FLAG_GST: 'REV_FLAG_GST',
    RECOVERABLE_FLAG_GST: 'RECOVERABLE_FLAG_GST',
    SECURITY_DEPOSIT_CAT: 'SECURITY_DEPOSIT_CAT',
    PARY_REQ_ADDRESS_BY_REQ_ID_VSET: 'PARY_REQ_ADDRESS_BY_REQ_ID_VSET',
    BILL_STATUS: 'BILL_STATUS',
    PARTY_REQ_VENDOR_TYPE_VSET: 'PARTY_REQ_VENDOR_TYPE_VSET',
    PARTY_REQ_APPROVAL_STATUS_VSET: 'PARTY_REQ_APPROVAL_STATUS_VSET',
    PARTY_REQ_ACCOUNTING_VSET: 'PARTY_REQ_ACCOUNTING_VSET',
    GST_RECONCILATION_STATUS_NEW: 'GST_RECONCILATION_STATUS_NEW',
    PLAN_UNIT_TYPE: 'PLAN_UNIT_TYPE',
    LEASE_PRJ_USING_FMS: 'LEASE_PRJ_USING_FMS',
    PARTY_RECEIPT_METHODS: 'PARTY_RECEIPT_METHODS',
    YES_NO: 'YES_NO',
    VENDOR_PORTAL_PAYMENT_TERMS: 'VENDOR_PORTAL_PAYMENT_TERMS',
    NFA_OUTWRIGHT_FMS_SUB_UNIT: 'NFA_OUTWRIGHT_FMS_SUB_UNIT',
    GST_URP: 'GST_URP',
    AGREEMENT_STATUS: 'AGREEMENT_STATUS',
    RATE_BASIS_VSET: 'RATE_BASIS_VSET',
    WORKDAY_CALENDAR: 'WORKDAY_CALENDAR',
    CUSTOM_FY: 'CUSTOM_FY',
    SALES_AGREEMENT: 'SALES_AGREEMENT',
    SALES_AGREEMENT_COMP: 'SALES_AGREEMENT_COMP',
    SALES_AGREEMENT_COMP_DTL: 'SALES_AGREEMENT_COMP_DTL',
    SHIFT_NAME: 'SHIFT_NAME',
    SELECT_COUNTRY: 'SELECT_COUNTRY',
    SELECT_STATE: 'SELECT_STATE',
    SELECT_CITY: 'SELECT_CITY',
    SELECT_PINCODE: 'SELECT_PINCODE',
    APPROVAL_STATUS_VSET: 'APPROVAL_STATUS_VSET',
    BACKUP_MODULE: 'BACKUP_MODULE',
    BACKUP_TABLES: 'BACKUP_TABLES',
    AWS_BACKUP_MONTHS_NAME: 'AWS_BACKUP_MONTHS_NAME',
    DELIVERY_TYPE: 'DELIVERY_TYPE',
    FA_LOCATION_STATUS: 'FA_LOCATION_STATUS',
    CHALLAN_LOC: 'CHALLAN_LOC',
    BILL_CYCLE_PERIOD: 'BILL_CYCLE_PERIOD',
    APPROVAL_STATUS_LEASE: 'APPROVAL_STATUS_LEASE',
    MODE_OF_TRANSPORT: 'MODE_OF_TRANSPORT',
    VEHICLE_TYPE: 'VEHICLE_TYPE',
    BANK_MODE_LIST: 'BANK_MODE_LIST',
    BANK_MODE_ACCOUNT: 'BANK_MODE_ACCOUNT',
    PARTY_METHOD_TYPE: 'PARTY_METHOD_TYPE',
    EMAIL_SETUP_TYPE: 'EMAIL_SETUP_TYPE',
    FA_LOCATION: 'FA_LOCATION',
    FA_LOCATION_TYPE: 'FA_LOCATION_TYPE',
    BILLING_PLAN_BY_COMP_ID: 'BILLING_PLAN_BY_COMP_ID',
    FA_LOCATION_NATURE_VSET: 'FA_LOCATION_NATURE_VSET',
    FA_LOB_VSET: 'FA_LOB_VSET',
    BILL_NATURE: 'BILL_NATURE',
    PR_EXTERNAL_FILE_NAME: 'PR_EXTERNAL_FILE_NAME',
    PR_EXTENSION: 'PR_EXTENSION',
    SALES_AGREEMENT_COMP_BY_TIMESHEET: 'SALES_AGREEMENT_COMP_BY_TIMESHEET',
    OVERTIME_UOM: 'OVERTIME_UOM',
    SALARY_COMPONENT_VSET: 'SALARY_COMPONENT_VSET',
    TRANSACTION_CLASS: 'TRANSACTION_CLASS',
    ERP_LEGAL_ENTITIES: 'ERP_LEGAL_ENTITIES',
    CUSTOMER_CLASS: 'CUSTOMER_CLASS',
    CUSTOMER_STATUS: 'CUSTOMER_STATUS',
    CMS_OU: 'CMS_OU',
    FA_ITEM_TYPE: 'FA_ITEM_TYPE',
    FA_ASSET_ITEMS: 'FA_ASSET_ITEMS',
    FA_ASSET_TAGS: 'FA_ASSET_TAGS',
    MANDATORY_STATUS_VSET: 'MANDATORY_STATUS_VSET',
    FA_DEPARTMENT: 'FA_DEPARTMENT',
    ASSET_TYPE_OF_WARRANTY: 'ASSET_TYPE_OF_WARRANTY',
    FA_ASSET_MANUFACTURER: 'FA_ASSET_MANUFACTURER',
    FA_ASSET_MODEL_TYPE: 'FA_ASSET_MODEL_TYPE',
    FREEZE_STATUS_VSET: 'FREEZE_STATUS_VSET',
    BILLING_PLAN_NAME_BY_TIMESHEET: 'BILLING_PLAN_NAME_BY_TIMESHEET',
    ASSET_DASHBOARD_STATUS: 'ASSET_DASHBOARD_STATUS',
    ASSET_STATUS_REPAIRTYPE: 'ASSET_STATUS_REPAIRTYPE',
    GSTR1_INVOICE_CATEGORIES: 'GSTR1_INVOICE_CATEGORIES',
    FMS_SALES_AGREEMENT_TIMESHEET: 'FMS_SALES_AGREEMENT_TIMESHEET',
    FMS_PRJ_TIMESHEET: 'FMS_PRJ_TIMESHEET',
    GSTR1_DOCUMENT_NATURE: 'GSTR1_DOCUMENT_NATURE',
    FMS_PRJ_BY_USER_ID: 'FMS_PRJ_BY_USER_ID',
    FMS_SALES_AGREEMENT_BY_USER: 'FMS_SALES_AGREEMENT_BY_USER',
    CCID_BY_LE: 'CCID_BY_LE',
    LEGAL_ENTITY_LIST_FUSION: 'LEGAL_ENTITY_LIST_FUSION',
    BUYER_GRN_VSET: 'BUYER_GRN_VSET',
    PARTY_NAME_WITH_ACCOUNT_NUMBER_FUSION:
      'PARTY_NAME_WITH_ACCOUNT_NUMBER_FUSION',
    TRX_NUMBER_FUSION: 'TRX_NUMBER_FUSION',
    LEASE_CUSTOMER_LIST_FOR_FMS: 'LEASE_CUSTOMER_LIST_FOR_FMS',
    LEASE_UNIT_LIST_FOR_FMS: 'LEASE_UNIT_LIST_FOR_FMS',
    INVOICE_BUYER_VSET: 'INVOICE_BUYER_VSET',
    INVOICE_GRN_CREATOR_VSET: 'INVOICE_GRN_CREATOR_VSET',
    INVOICE_EMPLOYEE_VSET: 'INVOICE_EMPLOYEE_VSET',
    VENDOR_SITE_VSET: 'VENDOR_SITE_VSET',
    OVER_TIME_UOM_HOUR_TYPE_VSET: 'OVER_TIME_UOM_HOUR_TYPE_VSET',
    TIMESHEET_STATUS: 'TIMESHEET_STATUS',
    ZERO_ONE: 'ZERO_ONE',
    GST_DOCUMENT_TYPE: 'GST_DOCUMENT_TYPE',
    GST_INVOICE_TYPE: 'GST_INVOICE_TYPE',
    TRANSACTION_CLASS_FUSION: 'TRANSACTION_CLASS_FUSION',
    EMP_ID_BY_GRP_ID: 'EMP_ID_BY_GRP_ID',
    FMS_SALES_AGREEMENT_BY_TIMESHEET_SUMMARY:
      'FMS_SALES_AGREEMENT_BY_TIMESHEET_SUMMARY',
    GL_PERIOD_GST: 'GL_PERIOD_GST',
    LOCATOR_VSET: 'LOCATOR_VSET',
    HOLD_MAPPING_LEVEL: 'HOLD_MAPPING_LEVEL',
    FUSION_BUSINESS_UNIT: 'FUSION_BUSINESS_UNIT',
    GET_RUN_ID: 'GET_RUN_ID',
    TRANSFER_STATUS: 'TRANSFER_STATUS',
    SOURCE_TYPE: 'SOURCE_TYPE',
    ASSET_MASTER_DETAILS_VSET: 'ASSET_MASTER_DETAILS_VSET',
    SUB_MODULE: 'SUB_MODULE',
    TRX_TYPE_FUSION: 'TRX_TYPE_FUSION',
    BANK_ACC_SCHEDULE: 'BANK_ACC_SCHEDULE',
    STATUS_FLAG: 'STATUS_FLAG',
    PARTY_REQ_TEMPLATE_TYPE: 'PARTY_REQ_TEMPLATE_TYPE',
    EMPLOYEE_TYPE: 'EMPLOYEE_TYPE',
    RFP_TYPE: 'RFP_TYPE',
    RFP_STATUS: 'RFP_STATUS',
    SELECT_REGION: 'SELECT_REGION',
    FA_ITEM_PROCESSOR: 'FA_ITEM_PROCESSOR',
    LEASE_PRJ_VSET: 'LEASE_PRJ_VSET',
    SOURCE_LE_OU_MOD: 'SOURCE_LE_OU_MOD',
    SOURCE_TYPE_NEW: 'SOURCE_TYPE_NEW',
    SENT_EMAIL_STATUS: 'SENT_EMAIL_STATUS',
    ASSET_DASH_STATUS: 'ASSET_DASH_STATUS',
    HR_LOCATION: 'HR_LOCATION',
    COMP_VSET: 'COMP_VSET',
    REMEDY_ACTION: 'REMEDY_ACTION',
    BILL_CAT_VSET: 'BILL_CAT_VSET',
    UPLOAD_PROVISION_STATUS_VSET: 'UPLOAD_PROVISION_STATUS_VSET',
    FMS_PRJ_INTERNAL: 'FMS_PRJ_INTERNAL',
    TYPE: 'TYPE',
    FMS_UNIT_DISPLAY: 'FMS_UNIT_DISPLAY',
    FMS_SUB_UNIT_DISPLAY: 'FMS_SUB_UNIT_DISPLAY',
    RFP_ATTRIBUTES: 'RFP_ATTRIBUTES',
    MANUAL_MODULE_VSET: 'MANUAL_MODULE_VSET',
    RFP_REGION: 'RFP_REGION',
    GSTR3B_PERIOD: 'GSTR3B_PERIOD',
    DUNNING_BASED_ON: 'DUNNING_BASED_ON',
    SA_USER: 'SA_USER',
    EWAY_BILL_DOC_TYPE: 'EWAY_BILL_DOC_TYPE',
    INTEREST_CALC_TYPE: 'INTEREST_CALC_TYPE',
    IS_HOLD_FLAG: 'IS_HOLD_FLAG',
    EWB_UNIT: 'EWB_UNIT',
    AUDIT_STATUS: 'AUDIT_STATUS',
    RELEASE_REASON_VSET: 'RELEASE_REASON_VSET',
    LEASEABLE_REASON_VSET: 'LEASEABLE_REASON_VSET',
    UNIT_TYPE: 'UNIT_TYPE',
    METER_TYPE: 'METER_TYPE',
    LEASE_UNIT_VSET: 'LEASE_UNIT_VSET',
    FMS_UNIT_DISPLAY_VSET: 'FMS_UNIT_DISPLAY_VSET',
    FMS_SUB_UNIT_DISPLAY_VSET: 'FMS_SUB_UNIT_DISPLAY_VSET',
    CUSTOMER_VSET: 'CUSTOMER_VSET',
    OWNERSHIP_TYPE_VSET: 'OWNERSHIP_TYPE_VSET',
    TRX_TYPE_VSET:'TRX_TYPE_VSET',
    PROVISION_EMP: 'PROVISION_EMP',
    CUSTOMER_SITE_VSET: 'CUSTOMER_SITE_VSET',
    PURCHASE_REQ_STATUS: 'PURCHASE_REQ_STATUS',
    READING_TYPE:'READING_TYPE',
    FMS_TOWER_VSET:'FMS_TOWER_VSET',
    GET_USER_NAME_BY_USER_ID:'GET_USER_NAME_BY_USER_ID',
    DASHBOARD_VSET:'DASHBOARD_VSET',
    METER_NO_VSET:'METER_NO_VSET',
    BILL_NO_BY_METER_NO:'BILL_NO_BY_METER_NO'

  },
  LOOKUPTYPE: {
    SALUTATION: 'SALUTATION',
    ADDRESS_TYPE: 'ADDRESS_TYPE',
    USER_TYPE: 'USER_TYPE',
    SECGRP: 'SECGRP',
    EMPLOYEE_TYPE: 'EMPLOYEE_TYPE',
    GENDER: 'GENDER',
    MARITAL_STATUS: 'MARITAL_STATUS',
    POSITION_CRITICALITY: 'POSITION_CRITICALITY',
    EMPLOYMENT_STATUS: 'EMPLOYMENT_STATUS',
    RELATION: 'RELATION',
    GRADE: 'GRADE',
    GST_INV_SOURCE_TYPE: 'GST_INV_SOURCE_TYPE',
    GET_ASSET_MASTER: 'GET_ASSET_MASTER'
  },
  DEFAULTDATE: '01-Jan-2099',
  DATEFORMAT: 'DD-MMM-YYYY',
  DATEFNSFORMAT: 'dd-MMM-yyyy',
  DATETIMEFNSFORMAT: 'dd-MMM-yyyy hh:mm:ss a',
  DEPENDENTVSETMSG: {
    LEMSG: 'Please select Legal Entity',
    OUMSG: 'Please select Operating Unit',
    BANDMSG: 'Please select Band',
    DESIGNATIONMSG: 'Please select Designation',
    CATEGORYMSG: 'Please select Category',
    SUBCATEGORYMSG: 'Please select Sub Category',
    MODULEMSG: 'Please select Module',
    ROLEMSG: 'Please select Role',
    REPORTGRPMSG: 'Please select Report Group',
    CRMMASTERPRJMSG: 'Please select CRM Master Project',
    CRMSECTORMSG: 'Please select CRM Sector',
    CRMPOCKETMSG: 'Please select CRM Pocket',
    CRMPROJECTMSG: 'Please select CRM Project',
    CRMTOWERMSG: 'Please select CRM Tower',
    CRMFLOORMSG: 'Please select CRM Floor',
    CRMUNITMSG: 'Please select CRM Unit',
    CRMSUBUNITMSG: 'Please select CRM SubUnit',
    FMSMASTERPRJMSG: 'Please select FMS Master Project', //
    FMSSECTORMSG: 'Please select FMS Sector',
    FMSPOCKETMSG: 'Please select FMS Pocket',
    FMSPROJECTMSG: 'Please select FMS Project',
    FMSTOWERMSG: 'Please select FMS Tower',
    FMSFLOORMSG: 'Please select FMS Floor',
    FMSUNITMSG: 'Please select FMS Unit',
    FMSSUBUNITMSG: 'Please select FMS SubUnit',
    PRJMASTERPRJMSG: 'Please select PRJ Master Project',
    PRJSECTORMSG: 'Please select PRJ Sector',
    PRJPOCKETMSG: 'Please select PRJ Pocket',
    PRJPROJECTMSG: 'Please select PRJ Project',
    PRJTOWERMSG: 'Please select PRJ Tower',
    PRJFLOORMSG: 'Please select PRJ Floor',
    PRJUNITMSG: 'Please select PRJ Unit',
    PRJSUBUNITMSG: 'Please select PRJ SubUnit',
    SYSTEMTABLESMSG: 'Please select System Tables',
    RULETYPEMSG: 'Please select Rule Type',
    ENTITYMSG: 'Please select Entity',
    COLUMNMSG: 'Please select Column',
    TAXGROUPMSG: 'Please select Tax Group',
    LEASEPRJMSG: 'Please select Lease Project',
    LEASETWRMSG: 'Please select Lease Tower',
    ORGNAMEMSG: 'Please Select Organisation',
    PROJECTMSG: 'Please select Project',
    TERMCONMSG: 'Please Select Term and Condition Type',
    PANERRORMSG: 'Please Enter Valid PAN Number'
  },
  UPDATIONTYPE: {
    DETAILS: 'Details',
    ADDRESS: 'Address',
    BANK: 'Bank',
    CONTACTDETAILS: 'ContactDetails',
    INDIRECTTAX: 'IndirectTax',
    DIRECTTAX: 'DirectTax'
  },
  MAILBODY: {
    CLOSING:
      '<br><br>' +
      '----------------------------------------------------------------------------------------------------<br>',
    TO: '<br>To: ',
    CC: '<br>CC: ',
    BCC: '<br>BCC: ',
    ON: '<br>on ',
    FOOTER:
      '<br><br><br><br><br><br>Please do not change the subject while replying'
  },
  specialChar: [
    '\\',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '+',
    '=',
    '{',
    '}',
    '[',
    ']',
    '|',
    "'",
    '"',
    ',',
    '.',
    '/',
    '.',
    '?',
    '<',
    '>'
  ],
  GSTUSERMANUALFILENAME: 'Realapp_User_Manual.pptx',
  EXCELFORMATEGSTEMAILSETUP: 'email_setup_uploader_specimen.xlsx',
  EXCELFORMATEITCRECON: 'claim_unclaim_uploader_specimen.xlsx',
  EXCELFORMATESALESREGISTER: 'sales_excel_uploader_specimen.xlsx',
  EXCELFORMATEINVOICESITCUPDATION: 'invoices_correction_uploader_specimen.xlsx',
  EXCELFORMATEPRITCUPDATION: 'purchase_register_uploader_specimen.xlsx',
  EXCELFORMATEVENDERHOLDMAPPING: 'VENDOR_ HOLD_UNHOLD_UPLOADER_SPECIMEN.xlsx'
};

export default appStrings;
